import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, unref as _unref, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "supplement-detail__remove-p" }
const _hoisted_2 = {
  key: 1,
  class: "l-container"
}
const _hoisted_3 = { class: "card" }
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "p-grid" }
const _hoisted_7 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_8 = { for: "name" }
const _hoisted_9 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_10 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_11 = { for: "internalName" }
const _hoisted_12 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_13 = { for: "types" }
const _hoisted_14 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_15 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_16 = { for: "shoppingUrl" }
const _hoisted_17 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_18 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_19 = { for: "description" }
const _hoisted_20 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_21 = {
  key: 0,
  class: "p-field p-col-12 p-fluid"
}
const _hoisted_22 = { class: "dicount-code__active" }
const _hoisted_23 = { for: "activeDiscountCode" }
const _hoisted_24 = {
  key: 1,
  class: "p-field p-col-6 p-fluid"
}
const _hoisted_25 = { for: "discountCode" }
const _hoisted_26 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_27 = {
  key: 1,
  class: "card-footer"
}

import { useI18n } from 'vue-i18n';
import { onMounted, ref, Ref, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

import HeadingComponent from '@/components/HeadingComponent.vue';
import DeleteSupplementModal from '@/components/supplements/DeleteSupplementModal.vue';
import { useSupplementsStore } from '@/store/supplements.module';
import { useProfileStore } from '@/store/profile.module';
import { SupplementType, SupplementDto, updateSupplementForm, isFormUpdateValid } from '@/models/Supplement';
import { supplementsService } from '@/services/SupplementsService';
import HealzInput from '@/components/healz/ui/HealzInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SupplementDetailPage',
  setup(__props) {

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const toast = useToast();
const storeProfile = useProfileStore();

const isAdminUser = computed(() => storeProfile.isAdmin);
const routeBreadcrumb = route.meta.breadcrumb as any[];
const breadcrumb = ref([] as Array<any>);

const supplementId = route.params.supplementId as string;
const deleteSupplementDialogRef = ref();
const supplement = ref();
const selectedSupplement = ref();
const supplementFormDto = ref<SupplementDto>();
const validateForm = computed(() => {
  if (!supplementFormDto.value) {
    return;
  }
  return updateSupplementForm(supplementFormDto.value);
});

const editMode = ref(false);
const waitSubmit = ref(false);
const showValidation = ref(false);
const activeDiscountCodeRef: Ref<boolean> = ref(false);

const visibleDiscountCode = computed(() => {
  return editMode.value && activeDiscountCodeRef.value;
});

const valid = computed(() => {
  if (!validateForm.value) {
    return false;
  }
  return isFormUpdateValid(validateForm.value);
});

const typesOptions = computed(() => {
  return Object.keys(SupplementType).map((key) => ({
    label: t(`supplementation.type.${key}`),
    value: key,
  }));
});

onMounted(async () => {
  if (!selectedSupplement.value && supplementId) {
    const supplementsStore = useSupplementsStore();
    await supplementsStore.getSupplementById(supplementId);
    selectedSupplement.value = supplementsStore.supplementSelected;
  }
  supplement.value = selectedSupplement.value;
  supplementFormDto.value = new SupplementDto(supplement.value);
  activeDiscountCodeRef.value =
    supplementFormDto.value?.discountCode != undefined && supplementFormDto.value?.discountCode != '';
  breadcrumb.value = [
    { label: t(routeBreadcrumb[0].parent), to: `/supplementation` },
    {
      label: supplement.value.name,
      to: '#',
      disabled: true,
    },
  ];
});

const onDeleteSupplement = () => {
  deleteSupplementDialogRef.value?.openDialog();
};

const onSupplementDeleted = () => {
  router.push(`/supplementation`);
};

const onCancelEdition = async () => {
  editMode.value = false;
  setInitialVaules();
};

const setInitialVaules = () => {
  if (supplementFormDto.value) {
    supplementFormDto.value = new SupplementDto(supplement.value);
  }
  activeDiscountCodeRef.value =
    supplementFormDto.value?.discountCode != undefined && supplementFormDto.value?.discountCode != '';
};

const onSubmit = async () => {
  showValidation.value = true;
  if (!valid.value) {
    return;
  }
  try {
    waitSubmit.value = true;
    if (supplementFormDto.value) {
      supplement.value = await supplementsService.update(supplementId, supplementFormDto.value);
    }
    toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditSupplement')}`, life: 3000 });
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: `${t('messages.notifications.errorEditSupplement')} ${t('messages.notifications.tryLater')}`,
      life: 3000,
    });
  } finally {
    waitSubmit.value = false;
    editMode.value = false;
  }
};

watch(
  () => activeDiscountCodeRef.value,
  async (newVal) => {
    if (!newVal && supplementFormDto.value) {
      supplementFormDto.value.discountCode = '';
    }
  },
);

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (supplement.value)
      ? (_openBlock(), _createBlock(HeadingComponent, {
          key: 0,
          title: supplement.value.name,
          "heading-icon": "icon-capsules",
          breadcrumb: breadcrumb.value
        }, _createSlots({ _: 2 }, [
          (isAdminUser.value)
            ? {
                name: "right",
                fn: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "supplement-detail__remove",
                    onClick: onDeleteSupplement
                  }, [
                    _cache[8] || (_cache[8] = _createElementVNode("i", {
                      class: "icon-trash_full supplement-detail__remove-icon",
                      "aria-hidden": "true"
                    }, null, -1)),
                    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('common.delete')), 1)
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["title", "breadcrumb"]))
      : _createCommentVNode("", true),
    (supplement.value)
      ? (_openBlock(), _createElementBlock("main", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(HeadingComponent, {
              title: _ctx.$t('supplementation.info-supplement'),
              divider: false,
              "sub-heading": true
            }, {
              right: _withCtx(() => [
                _renderSlot(_ctx.$slots, "headingActions", {}, () => [
                  _withDirectives((_openBlock(), _createBlock(_component_Button, {
                    class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", editMode.value ? 'p-disabled' : '']),
                    disabled: !isAdminUser.value,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (editMode.value = !editMode.value))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("i", {
                        class: "icon-edit",
                        "aria-label": _ctx.$t('common.edit'),
                        "aria-hidden": "true"
                      }, null, 8, _hoisted_4)
                    ]),
                    _: 1
                  }, 8, ["class", "disabled"])), [
                    [
                      _directive_tooltip,
                      _ctx.$t('common.edit'),
                      void 0,
                      { bottom: true }
                    ]
                  ])
                ])
              ]),
              _: 3
            }, 8, ["title"]),
            (supplementFormDto.value)
              ? (_openBlock(), _createElementBlock("form", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('supplementation.create-modal.name')) + _toDisplayString(editMode.value ? '*' : ''), 1),
                      _createVNode(HealzInput, {
                        id: "name",
                        modelValue: supplementFormDto.value.name,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((supplementFormDto.value.name) = $event)),
                        modelModifiers: { trim: true },
                        editable: editMode.value,
                        required: "true",
                        class: _normalizeClass({ 'p-invalid': showValidation.value && !validateForm.value?.name.valid })
                      }, null, 8, ["modelValue", "editable", "class"]),
                      (showValidation.value && !validateForm.value?.name.valid)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.$t(`${validateForm.value?.name.message}`)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('supplementation.create-modal.internalName')), 1),
                      _createVNode(HealzInput, {
                        id: "internalName",
                        modelValue: supplementFormDto.value.internalName,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((supplementFormDto.value.internalName) = $event)),
                        modelModifiers: { trim: true },
                        editable: editMode.value
                      }, null, 8, ["modelValue", "editable"])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('supplementation.create-modal.type')) + "*", 1),
                      _createVNode(_component_MultiSelect, {
                        id: "types",
                        modelValue: supplementFormDto.value.types,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((supplementFormDto.value.types) = $event)),
                        options: typesOptions.value,
                        "option-label": "label",
                        "option-value": "value",
                        class: _normalizeClass({ 'p-invalid': showValidation.value && !validateForm.value?.types.valid, 'no-editable': !editMode.value })
                      }, null, 8, ["modelValue", "options", "class"]),
                      (showValidation.value && !validateForm.value?.types.valid)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_14, _toDisplayString(_ctx.$t(`${validateForm.value?.types.message}`)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('supplementation.create-modal.shoppingUrl')), 1),
                      _createVNode(HealzInput, {
                        id: "shoppingUrl",
                        modelValue: supplementFormDto.value.shoppingUrl,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((supplementFormDto.value.shoppingUrl) = $event)),
                        modelModifiers: { trim: true },
                        required: "true",
                        editable: editMode.value,
                        class: _normalizeClass({ 'p-invalid': showValidation.value && !validateForm.value?.shoppingUrl.valid })
                      }, null, 8, ["modelValue", "editable", "class"]),
                      (showValidation.value && !validateForm.value?.shoppingUrl.valid)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_17, _toDisplayString(_ctx.$t(`${validateForm.value?.shoppingUrl.message}`)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('supplementation.create-modal.description')), 1),
                      _createVNode(_component_Textarea, {
                        id: "description",
                        modelValue: supplementFormDto.value.description,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((supplementFormDto.value.description) = $event)),
                        class: _normalizeClass({ 'p-invalid': showValidation.value && !validateForm.value?.description.valid, 'no-editable': !editMode.value }),
                        rows: "3",
                        editable: editMode.value,
                        "auto-resize": true,
                        type: "text"
                      }, null, 8, ["modelValue", "class", "editable"]),
                      (showValidation.value && !validateForm.value?.description.valid)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_20, _toDisplayString(_ctx.$t(`${validateForm.value?.description.message}`)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    (editMode.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          _createElementVNode("div", _hoisted_22, [
                            _createVNode(_component_InputSwitch, {
                              modelValue: activeDiscountCodeRef.value,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((activeDiscountCodeRef).value = $event)),
                              inputId: "activeDiscountCode"
                            }, null, 8, ["modelValue"]),
                            _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('supplementation.create-modal.discountCode')), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (!editMode.value || visibleDiscountCode.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                          _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('supplementation.create-modal.discountCode')), 1),
                          _createVNode(HealzInput, {
                            id: "discountCode",
                            modelValue: supplementFormDto.value.discountCode,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((supplementFormDto.value.discountCode) = $event)),
                            modelModifiers: { trim: true },
                            required: "true",
                            editable: editMode.value,
                            class: _normalizeClass({ 'p-invalid': showValidation.value && !validateForm.value?.discountCode.valid })
                          }, null, 8, ["modelValue", "editable", "class"]),
                          (showValidation.value && !validateForm.value?.discountCode.valid)
                            ? (_openBlock(), _createElementBlock("small", _hoisted_26, _toDisplayString(_ctx.$t(`${validateForm.value?.discountCode.message}`)), 1))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (editMode.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                  _createVNode(_component_Button, {
                    class: "p-button-secondary p-button-medium",
                    label: _ctx.$t('common.cancel'),
                    onClick: onCancelEdition
                  }, null, 8, ["label"]),
                  _createVNode(_component_Button, {
                    class: "p-button p-button-info p-button-medium",
                    loading: waitSubmit.value,
                    label: _ctx.$t('common.save'),
                    onClick: onSubmit
                  }, null, 8, ["loading", "label"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(DeleteSupplementModal, {
      ref_key: "deleteSupplementDialogRef",
      ref: deleteSupplementDialogRef,
      supplementId: _unref(supplementId),
      onSupplementDeleted: onSupplementDeleted
    }, null, 8, ["supplementId"])
  ], 64))
}
}

})