
import { supplementsService } from '@/services/SupplementsService';
import { useToast } from 'primevue/usetoast';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  emits: ['supplement-deleted'],
  props: {
    supplementId: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();

    const showDialog = ref(false);
    const waitSubmit = ref(false);

    const openDialog = () => {
      showDialog.value = true;
    };

    const onSubmit = async () => {
      await deleteSupplement();
    };

    async function deleteSupplement() {
      waitSubmit.value = true;
      await supplementsService
        .delete(props.supplementId)
        .then(() => {
          toast.add({
            severity: 'success',
            summary: `${t('messages.notifications.successDeleteSupplement')}`,
            life: 3000,
          });
          emit('supplement-deleted');
          showDialog.value = false;
        })
        .catch(() => {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorDeleteSupplement')}`,
            life: 3000,
          });
        })
        .finally(() => {
          waitSubmit.value = false;
        });
    }

    return {
      showDialog,
      waitSubmit,
      openDialog,
      onSubmit,
    };
  },
});
