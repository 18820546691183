import { isObjectValid, required, ValidationResult } from '@/validation/form';
import { UserTableDto } from './User';
import dateFormat from '@/helpers/DateFormat.helper';

interface Supplement {
  id: string;
  professional: UserTableDto;
  name: string;
  internalName: string;
  shoppingUrl: string;
  discountCode?: string;
  description: string;
  creationDate: string;
  types: SupplementType[];
}

interface PatientSupplement {
  id: string;
  supplement: Supplement;
  professional: UserTableDto;
  unit: SupplementUnit;
  amount: number;
  numberOfTakes: number;
  frequencyOfTakes: string;
  startDate: string;
  endDate: string;
  observations: string;
  creationDate: string;
}

interface PatientSupplementTableDto {
  id: string;
  name: string;
  types: SupplementType[];
  shoppingUrl: string;
  professional: string;
  startDate: string;
  endDate: string;
}

class PatientSupplementDto {
  supplementId: string;
  unit: SupplementUnit;
  amount: number | undefined;
  numberOfTakes: number | undefined;
  frequencyOfTakes: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  observations: string;

  constructor(patientSupplement: PatientSupplement) {
    this.supplementId = patientSupplement.supplement ? patientSupplement.supplement.id : '';
    this.unit = patientSupplement.unit;
    this.amount = patientSupplement.amount ? Number(patientSupplement.amount) : undefined;
    this.numberOfTakes = patientSupplement.numberOfTakes ? Number(patientSupplement.numberOfTakes) : undefined;
    this.frequencyOfTakes = patientSupplement.frequencyOfTakes;
    this.startDate = patientSupplement.startDate
      ? new Date(dateFormat.utcToUserTimezone(patientSupplement.startDate))
      : undefined;
    this.endDate = patientSupplement.endDate
      ? new Date(dateFormat.utcToUserTimezone(patientSupplement.endDate))
      : undefined;
    this.observations = patientSupplement.observations;
  }
}

enum SupplementUnit {
  GR = 'GR',
  MG = 'MG',
  ML = 'ML',
  UNIT = 'UNIT',
}

enum SupplementType {
  VITAMINS_AND_MINERALS = 'VITAMINS_AND_MINERALS',
  PROTEIN = 'PROTEIN',
  AMINO_ACIDS = 'AMINO_ACIDS',
  STRESS_CONTROL = 'STRESS_CONTROL',
  SPORT_PERFORMANCE = 'SPORT_PERFORMANCE',
  FAT_BURNER = 'FAT_BURNER',
  DIGESTIVE_HEALTH = 'DIGESTIVE_HEALTH',
  ARTICULAR_HEALTH = 'ARTICULAR_HEALTH',
  ANTI_INFLAMMATORY = 'ANTI_INFLAMMATORY',
  ANTIOXIDANTS = 'ANTIOXIDANTS',
  SLEEP = 'SLEEP',
  IMMUNE_SYSTEM = 'IMMUNE_SYSTEM',
  METABOLIC_HEALTH = 'METABOLIC_HEALTH',
  VITAMIN = 'VITAMIN',
  HORMONAL_HEALTH = 'HORMONAL_HEALTH',
  CARDIOVASCULAR_HEALTH = 'CARDIOVASCULAR_HEALTH',
}

class SupplementDto {
  name: string;
  internalName: string;
  shoppingUrl: string;
  discountCode: string;
  description: string;
  types: SupplementType[];

  constructor(supplement: Supplement) {
    this.name = supplement.name;
    this.internalName = supplement.internalName;
    this.shoppingUrl = supplement.shoppingUrl;
    this.discountCode = supplement.discountCode ?? '';
    this.description = supplement.description;
    this.types = supplement.types;
  }
}

interface CreateSupplementFormValidity {
  name: ValidationResult;
  shoppingUrl: ValidationResult;
  discountCode: ValidationResult;
  description: ValidationResult;
  types: ValidationResult;
}

interface UpdateSupplementFormValidity {
  name: ValidationResult;
  shoppingUrl: ValidationResult;
  discountCode: ValidationResult;
  description: ValidationResult;
  types: ValidationResult;
}

interface PatientSupplementFormValidity {
  supplementId: ValidationResult;
  unit: ValidationResult;
  amount: ValidationResult;
  numberOfTakes: ValidationResult;
  frequencyOfTakes: ValidationResult;
  startDate: ValidationResult;
  endDate: ValidationResult;
  observations: ValidationResult;
}

function createSupplementForm(supplement: SupplementDto, activeDiscountCode: boolean): CreateSupplementFormValidity {
  const name = required(supplement.name);
  const shoppingUrl = { valid: true, message: '' };
  const discountCode = activeDiscountCode ? required(supplement.discountCode) : { valid: true, message: '' };
  const description = { valid: true, message: '' };
  const types = required(supplement.types);

  return {
    name,
    shoppingUrl,
    discountCode,
    description,
    types,
  };
}

function updateSupplementForm(supplement: SupplementDto): UpdateSupplementFormValidity {
  const name = required(supplement.name);
  const shoppingUrl = { valid: true, message: '' };
  const discountCode = { valid: true, message: '' };
  const description = { valid: true, message: '' };
  const types = required(supplement.types);

  return {
    name,
    shoppingUrl,
    discountCode,
    description,
    types,
  };
}

function patientSupplementForm(patientSupplement: PatientSupplementDto): PatientSupplementFormValidity {
  const supplementId = required(patientSupplement.supplementId);
  const unit = required(patientSupplement.unit);
  const amount = required(patientSupplement.amount);
  const numberOfTakes = required(patientSupplement.numberOfTakes);
  const frequencyOfTakes = required(patientSupplement.frequencyOfTakes);
  const startDate = required(patientSupplement.startDate);
  const endDate = required(patientSupplement.endDate);
  const observations = { valid: true, message: '' };

  return {
    supplementId,
    unit,
    amount,
    numberOfTakes,
    frequencyOfTakes,
    startDate,
    endDate,
    observations,
  };
}

function isFormCreateValid(form: CreateSupplementFormValidity) {
  return isObjectValid<CreateSupplementFormValidity>(form);
}

function isFormUpdateValid(form: UpdateSupplementFormValidity) {
  return isObjectValid<UpdateSupplementFormValidity>(form);
}

function isPatientFormCreateValid(form: PatientSupplementFormValidity) {
  return isObjectValid<PatientSupplementFormValidity>(form);
}

function isPatientFormUpdateValid(form: PatientSupplementFormValidity) {
  return isObjectValid<PatientSupplementFormValidity>(form);
}

export {
  Supplement,
  SupplementDto,
  SupplementType,
  CreateSupplementFormValidity,
  createSupplementForm,
  updateSupplementForm,
  isFormCreateValid,
  isFormUpdateValid,
  PatientSupplement,
  PatientSupplementDto,
  SupplementUnit,
  patientSupplementForm,
  PatientSupplementFormValidity,
  isPatientFormCreateValid,
  isPatientFormUpdateValid,
  PatientSupplementTableDto,
};
