import { defineStore } from 'pinia';
import { Supplement } from '@/models/Supplement';
import { supplementsService } from '@/services/SupplementsService';
import { ServiceError } from '@/services/util/ServiceError';

export const useSupplementsStore = defineStore('supplements-store', {
  state: () => ({
    supplements: undefined as Supplement[] | undefined,
    supplementSelected: undefined as Supplement | undefined,
  }),
  getters: {
    getSupplements: (applicationState: any) => applicationState.supplements,
    getSupplementSelected: (applicationState: any) => applicationState.supplementSelected,
    getSupplementById: (applicationState: any) => async (id: string) => {
      await applicationState.fetchSupplements().then(() => {
        applicationState.supplementSelected = applicationState.supplements?.find(
          (supplement: Supplement) => supplement.id === id,
        );
      });
    },
  },
  actions: {
    setSupplements(payload: Supplement[]) {
      this.supplements = payload;
    },
    setSupplementSelected(payload: any) {
      this.supplementSelected = payload;
    },
    async fetchSupplements() {
      const result = await supplementsService.findAll();
      if (!(result instanceof ServiceError)) {
        this.setSupplements(result);
      }
    },
  },
});
