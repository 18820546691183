import { Supplement, SupplementDto, PatientSupplement, PatientSupplementDto } from '@/models/Supplement';
import { useProfileStore } from '@/store/profile.module';
import axios, { AxiosInstance } from 'axios';
import handleError from './util/ErrorHandler';
import { ServiceError } from './util/ServiceError';

let axiosInstance: AxiosInstance;
const storeProfile = useProfileStore();

class SupplementsService {
  constructor() {
    axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        'Content-type': 'application/json',
      },
    });
  }

  async findAll(): Promise<Supplement[] | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    try {
      const response = await axiosInstance.get<Supplement[]>(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/supplement?accountId=${storeProfile.accountId}`,
      );

      console.debug(`>[SupplementsService] Succeed : request findAll. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[SupplementsService] Error: request findAll`);
      return handleError(error);
    }
  }

  async create(supplement: SupplementDto): Promise<Supplement | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.post(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/supplement?accountId=${storeProfile.accountId}`,
        supplement,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async update(supplementId: string, supplement: SupplementDto): Promise<Supplement | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/supplement/${supplementId}?accountId=${storeProfile.accountId}`,
        supplement,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async delete(supplementId: string): Promise<Supplement | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.delete(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/supplement/${supplementId}?accountId=${storeProfile.accountId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async findAllByPatient(patientId: string): Promise<PatientSupplement[] | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    try {
      const response = await axiosInstance.get<any[]>(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/supplement-patient?accountId=${storeProfile.accountId}&patientId=${patientId}`,
      );

      console.debug(`>[SupplementsService] Succeed : request findAllByPatient. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[SupplementsService] Error: request findAllByPatient`);
      return handleError(error);
    }
  }

  async findByIdByPatient(supplementId: string, patientId: string): Promise<PatientSupplement | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    try {
      const response = await axiosInstance.get<any>(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/supplement-patient/${supplementId}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
      );

      console.debug(`>[SupplementsService] Succeed : request findByIdByPatient. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[SupplementsService] Error: request findByIdByPatient`);
      return handleError(error);
    }
  }

  async createByPatient(
    supplement: PatientSupplementDto,
    patientId: string,
  ): Promise<PatientSupplement | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.post(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/supplement-patient?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        supplement,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async updateByPatient(
    supplementId: string,
    supplement: PatientSupplementDto,
    patientId: string,
  ): Promise<PatientSupplement | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/supplement-patient/${supplementId}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        supplement,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async deleteByPatient(supplementId: string, patientId: string): Promise<PatientSupplement | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.delete(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/supplement-patient/${supplementId}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }
}

export const supplementsService = new SupplementsService();
